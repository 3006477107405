
import { Component, Vue } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'
import { wareHousingList, AssetList } from '@/types/assetScheduling'
import { ProjectUser } from '@/types/project'
import Dialog from '@/components/assetScheduling/Dialog.vue'

@Component({
  name: 'RequisitionRefundAdd',
  components: { Dialog }
})
export default class ScheduleAdd extends Vue {
  private showDialog = false
  private info: wareHousingList = {
    orderDate: '',
    projectId: '',
    remarks: '',
    assetList: [],
    recipientId: ''
  }

  private projectUserList: ProjectUser[] = []

  pickerOptions = {
    disabledDate: (time: Date) => {
      // 大于当前日期禁止选择
      return time.getTime() > Date.now()
    }
  }

  private rules = {
    projectId: [
      { required: true, message: '请选择项目名称', trigger: ['blur', 'change'] }
    ],
    orderDate: [
      { required: true, message: this.isRefund ? '请选择退还时间' : '请选择领用时间', trigger: ['blur', 'change'] }
    ],
    recipientId: [
      { required: true, message: !this.isRefund ? '请选择领用人' : '', trigger: ['blur', 'change'] }
    ]
  }

  private tips = this.isRefund ? '仅处于借用状态的资产才可退还，如果退还资产非借用，请联系保管员进行资产更新' : '仅处于空闲状态的资产才可领用，如果领用资产非空闲，请联系保管员进行资产更新'

  get projectList () {
    return this.$store.state.projectList
  }

  get assetCode () {
    return this.$route.params.assetCode
  }

  get userId () {
    const userInfo = window.sessionStorage.getItem('userInfo') || ''
    return userInfo ? JSON.parse(userInfo).userId : ''
  }

  // 是否退还
  get isRefund () {
    return this.$route.path.split('/').length > 2 && this.$route.path.split('/')[2].indexOf('refund') !== -1
  }

  private title = this.isRefund ? '退还' : '领用'

  created () {
    this.info.projectId = this.$route.params.projectId as string || ''
    this.info.projectId && this.getProjectUserList()
    this.assetCode && this.getOnlyTable()
    // console.log('create新增领用', this.$route.params)
    if (this.$route.params.custodianId) {
      this.info.recipientId = this.$route.params.custodianId
    }
    if (this.title === '领用') {
      this.info.orderDate = this.$dayjs().format('YYYY-MM-DD')
    }
  }

  // 获取项目下所有人信息
  getProjectUserList () {
    this.info.assetList = []
    if (!this.isRefund) {
      this.info.recipientId = ''
    }
    this.$axios.get(this.$apis.project.selectProjectUserByList, {
      projectId: this.info.projectId
    }).then((res) => {
      this.projectUserList = res.list || []
      // 如果当前登录人属于该项目，默认当前登录人为领用人
      const flag = this.projectUserList.some((item: any) => {
        return item.userId === this.userId
      })
      if (flag) {
        this.info.recipientId = this.userId
      }
    })
  }

  onAdd () {
    if (!this.info.projectId) {
      this.$message.warning('请先选择项目！')
      return false
    }
    this.showDialog = true
    ;(this.$refs.dialog as any).toggleSelection(this.info.assetList)
  }

  success (val: AssetList[]) {
    this.info.assetList = val
  }

  onDelete (index: number) {
    this.info.assetList.splice(index, 1)
  }

  submit (callback: Function) {
    ;(this.$refs.info as ElForm).validate(valid => {
      if (valid) {
        if (this.info.assetList.length === 0) {
          this.$message.warning('请先选择要' + this.title + '的资产！')
          return false
        }
        if (this.isRefund) {
          delete this.info.recipientId
        }
        this.$axios.post(this.isRefund ? this.$apis.assetScheduling.insertAssetReturn : this.$apis.assetScheduling.insertAssetReceive, {
          ...this.info
        }).then(() => {
          this.$message.success('保存成功')
          callback()
        })
      }
    })
  }

  onSubmit () {
    this.submit(() => {
      this.$router.back()
    })
  }

  // 获取本条信息
  getOnlyTable () {
    this.$axios.get(this.$apis.assetScheduling.selectAssetByPage, {
      assetCode: this.assetCode
    }).then(res => {
      this.info.assetList = res.list || []
    })
  }
}
